import axios from 'axios';

class PaymentAPI {
  static getPaymentIntent = async ({
    accountId,
    draftCampaignId,
    priceId,
    returnUrl,
  }: {
    accountId: string;
    draftCampaignId: string;
    priceId: string;
    returnUrl: string;
  }) =>
    await axios.post<{ clientSecret: string }>('/payment/web/create-payment-session', {
      accountId,
      draftCampaignId,
      priceId,
      returnUrl,
    });

  static getSubscriptionPaymentIntent = async ({
    accountId,
    priceId,
    returnUrl,
  }: {
    accountId: string;
    priceId: string;
    returnUrl: string;
  }) =>
    await axios.post<{ clientSecret: string }>('/payment/web/create-subscription-session', {
      accountId,
      priceId,
      returnUrl,
    });

  static getPaymentSessionStatus = async ({ sessionId }: { sessionId: string }) =>
    await axios.get<{ sessionState: string }>(`/payment/web/payment-session-status/${sessionId}`);

  static postSubscriptionManageUrl = async ({ accountId, returnUrl }: { accountId: string; returnUrl: string }) =>
    await axios.post<{ url: string }>('payment/web/create-customer-portal', { accountId, returnUrl });
}

export default PaymentAPI;
